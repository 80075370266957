<template>
  <div class="step-content">
    <div class="d-block">
      <div class="title" style="max-width: 410px;">{{ $t('questions.step_3.title') }}</div>
      <div class="check-all">{{ $t('questions.step_3.description') }}</div>
      <SelectBox @update-val="updateVal"
                 :shadow="true"
                 ref="selectBox"
                 :check-data="$t('questions.step_3.checkbox')"/>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <RestartBtn/>
      <router-link class="btn mt-0" to="4" :class="{disabled: !selected.length}">{{
          $t('questions.step_3.btnText')
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SelectBox from "../components/SelectBox";
import RestartBtn from "../components/RestartBtn";
import {updateVal} from "../mixins/update-val";

export default {
  name: 'Step_3',
  mixins: [updateVal],
  components: {
    SelectBox,
    RestartBtn
  },
  methods: {
    restart() {
      this.selected = []
      this.$refs.selectBox.setVal(this.selected)
    }
  }
}
</script>
